<template>
  <Container>
    <v-card-text>
      <p class="mb-2">
        Bạn sẽ nhận được email chứa thông tin hướng dân đặt lại mật khẩu
      </p>
    </v-card-text>

    <v-card-text>
      <v-form @submit.prevent="login">
        <v-text-field
          v-model="email"
          :error-messages="emailError"
          :hide-details="emailError===''"
          outlined
          :label="$t('email')"
          class="mb-3"
        ></v-text-field>

        <p
          v-if="success"
        >
          <v-icon
            color="success"
            left
          >
            mdi-check-circle-outline
          </v-icon>
          <span class="text-subtitle-2">Hướng dẫn đã được gửi</span>
        </p>

        <v-btn
          block
          color="primary"
          class="mt-6"
          type="submit"
        >
          <v-progress-circular
            v-if="loading"
            :size="14"
            :width="2"
            indeterminate
          />
          <span v-else>Gửi hướng dẫn</span>
        </v-btn>
      </v-form>
    </v-card-text>
  </Container>
</template>

<script>

import { ref } from '@vue/composition-api'
import Container from '../components/Container.vue'

export default {
  components: {
    Container,
  },

  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')

    return {
      isPasswordVisible,
      email,
    }
  },

  data() {
    return {
      errors: {},
      message: '',
      loading: false,
      success: false,
      email: '',
    }
  },

  computed: {
    emailError() {
      return this.errors.email || ''
    },
  },

  methods: {
    login() {
      this.loading = true
      this.errors = {}
      this.$http.post('/api/auth/user/forget-password', { email: this.email })
        .then(() => {
          this.success = true
        })
        .catch(err => {
          if (err.response.data.errors) {
            this.errors = err.response.data.errors
          } else if (err.response.data.message) {
            this.errors = { email: err.response.data.message }
          } else {
            this.errors = { email: 'Something is wrong!' }
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
